/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { ReCaptcha } from 'react-recaptcha-v3'
import { nanoid } from 'nanoid'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import InfoGrayIcon from '../../assets/files/info_gray.svg'

import { User } from '../../utils/models'

import {
  Container,
  Box,
  BoxLeft,
  BoxRight,
  TextFoot,
  Label,
  Button,
  BoxTextAndCard,
  BoxTwoImages,
  FormButton,
} from './styles'
import { api } from '../../services/api'
import Input from './components/Input'
import Recaptcha from './components/Recaptcha'
import VisibilityOff from '../../assets/files/visibility_off.svg'
import VisibilityOn from '../../assets/files/visibility.svg'

interface Errors {
  [key: string]: string
}

const TWO_MINUTES = 2 * 60000

const Login: React.FC = ({ modules, dispatch }: any) => {
  const [loading, setLoading] = useState(false)
  const [loadingUC, setLoadingUC] = useState(false)
  const [loadingCaptcha, setLoadingCaptcha] = useState(true)
  // const [password,setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const UcRef = useRef<any>(null)
  const formRef = useRef<any>(null)
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const [captcha, setCaptcha] = useState<ReCaptcha>()

  const [focusOn, setFocusOn] = useState<number | undefined>()

  function setClient(module: any, data: User): unknown {
    return {
      type: 'SET_UC',
      module,
      data,
    }
  }

  function setLimited(module: any, limited: boolean): unknown {
    return {
      type: 'SET_LIMITED',
      module,
      limited,
    }
  }

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        if (googleRecaptcha) {
          // console.log('recaptcha')
          setGoogleRecaptcha(undefined)
          captcha?.execute()
        }
      }, TWO_MINUTES)
  }, [googleRecaptcha])

  function createUCToken(UC: string): string {
    let token = ''
    if (process.env.REACT_APP_TOKEN) {
      token = process.env.REACT_APP_TOKEN + UC
      const d = new Date()
      const timezone = 'America/Sao_Paulo'
      const zonedDate = utcToZonedTime(d, timezone)

      const formatedDate = format(zonedDate, 'ddMMyyyy')

      token += formatedDate
    }

    return token
  }

  async function handleSubmitUC(data: any): Promise<void> {
    // console.log(UcRef.current)
    localStorage.clear()
    sessionStorage.clear()
    if (UcRef.current) {
      try {
        UcRef.current.setErrors({})
        // console.log(data)

        const schema = Yup.object().shape({
          UC: Yup.string()
            .min(3, 'No mínimo 3 caracteres')
            .required('Formato inválido'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        localStorage.clear()
        sessionStorage.clear()
        if (googleRecaptcha) {
          handleConsultUC(data.UC)
        } else {
          captcha?.execute()
          handleConsultUC(data.UC)
        }
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          UcRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function handleSubmit(data: any): Promise<void> {
    localStorage.clear()
    sessionStorage.clear()
    if (formRef.current) {
      try {
        formRef.current.setErrors({})
        // console.log(data)

        const schema = Yup.object().shape({
          document: Yup.string()
            .min(11, 'No mínimo 11 caracteres')
            .required('Formato inválido'),
          password: Yup.string().required('Formato inválido'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        localStorage.clear()
        sessionStorage.clear()
        if (googleRecaptcha) {
          handleConsult(data.document, data.password)
        } else {
          captcha?.execute()
          handleConsult(data.document, data.password)
        }
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  function setNameClient(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }

  async function handleConsultUC(docField: string): Promise<void> {
    setLoadingUC(true)
    const noEncript = `${nanoid()}:${
      process.env.REACT_APP_TOKEN ? createUCToken(docField) : nanoid()
    }`
    const baseEncript = btoa(noEncript)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const { data } = await api.get<any, AxiosResponse<User>>(
        `/ref/${docField}/current`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      dispatch(setClient(modules, data))
      dispatch(setNameClient(modules, data.NOME_CLIENTE || 'SEM NOME'))
      // dispatch(setLimited(modules,true))
      sessionStorage.setItem('infoPermission', 'false')
      localStorage.setItem('infoClient', JSON.stringify(data))
      localStorage.setItem('info', JSON.stringify(data))
      if (!data) {
        if (UcRef.current) UcRef.current.setErrors({ UC: 'Tente novamente' })
        captcha?.execute()
        setLoadingCaptcha(true)
        setLoadingUC(false)
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        history.push('/login')
      } else {
        setLoadingUC(false)
        localStorage.setItem('first_time', 'true')
        history.push('/')
      }
    } catch (error) {
      if (UcRef.current)
        UcRef.current.setErrors({ document: 'Tente novamente' })
      captcha?.execute()
      setLoadingCaptcha(true)
      setLoadingUC(false)
      localStorage.clear()
      sessionStorage.clear()
      dispatch({
        type: 'RESET',
      })
      history.push('/login')
    }
  }

  async function handleConsult(
    docField: string,
    password: string
  ): Promise<void> {
    setLoading(true)
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const clearDoc = docField.replace(/\D/g, '')
      const userData = {
        username: clearDoc,
        password,
      }
      const { data } = await api.post<any, AxiosResponse<any>>(
        `/${clearDoc}/refs`,
        userData,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      if (data && data.length > 0) {
        if (data.length > 1) {
          localStorage.setItem('infoClient', JSON.stringify(data))
          dispatch(setClient(modules, data[0]))
          dispatch(setNameClient(modules, data[0].NOME_CLIENTE || 'SEM NOME'))
          // dispatch(setLimited(modules,true))
          sessionStorage.setItem('infoPermission', 'true')

          setLoadingCaptcha(true)
          setLoading(false)
          localStorage.setItem('first_time', 'true')
          history.push('/uc')
        } else {
          dispatch(setClient(modules, data[0]))
          dispatch(setNameClient(modules, data?.NOME_CLIENTE))
          // dispatch(setLimited(modules,true))
          sessionStorage.setItem('infoPermission', 'true')

          localStorage.setItem('infoClient', JSON.stringify(data))
          localStorage.setItem('info', JSON.stringify(data[0]))
          setLoading(false)
          localStorage.setItem('first_time', 'true')
          history.push('/')
        }
      } else {
        if (formRef.current)
          formRef.current.setErrors({ document: 'Tente novamente' })
        captcha?.execute()
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        setLoadingCaptcha(true)
        setLoading(false)
      }
    } catch (error) {
      if (formRef.current)
        formRef.current.setErrors({
          document: 'Usuário ou senha incorretos',
          password: 'Usuário ou senha incorretos',
        })
      captcha?.execute()
      setLoadingCaptcha(true)
      setLoading(false)
      localStorage.clear()
      sessionStorage.clear()
      dispatch({
        type: 'RESET',
      })
      history.push('/login')
    }
  }

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
      type: 'RESET',
    })
  }, [])

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false)
    setGoogleRecaptcha(recaptchaToken)
  }

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true)
    } else {
      setLoadingCaptcha(false)
    }
  }, [googleRecaptcha])

  return (
    <Container>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <span className="thisRecap">
        <Recaptcha googleRecapt={!loadingCaptcha} />
      </span>
      <Box>
        <BoxLeft>
          <BoxTwoImages>
            <img
              id="imgText"
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo-mobile.svg`}
              alt="Imagem"
            />
          </BoxTwoImages>
          <BoxTextAndCard>
            <h1 className="box_left_title">
              Agora ficou fácil pagar as contas de energia
            </h1>
            <p className="box_left_small mb-15">
              Tá com pressa? Use nosso{' '}
              <span className="box_left_bold">Acesso Fácil</span>: informe a UC,
              identifique o débito e faça o pagamento. Fácil!{' '}
            </p>
            <p className="box_left_small mb-30">
              Quer mais detalhes?{' '}
              <span className="box_left_bold">Cadastre-se</span>. Além de
              efetuar pagamentos, tenha acesso ao seu histórico, Vias e
              Comprovantes de Pagamento.
            </p>

            {process.env.REACT_APP_AGENCIA ? (
              <a
                href={`${process.env.REACT_APP_AGENCIA}/cadastrar-senha`}
                target="__blank"
                className="box_left_button"
                // type="button"
              >
                Cadastrar agora
              </a>
            ) : process.env.REACT_APP_SERVICES_LINK ? (
              <a
                href={`${process.env.REACT_APP_SERVICES_LINK}`}
                target="__blank"
                className="box_left_button"
              >
                Cadastrar agora
              </a>
            ) : null}
          </BoxTextAndCard>
          <div className="box_left_footer align_self_start">
            <span>Desenvolvido por</span>
            <img
              style={{ marginLeft: 6 }}
              alt="orange"
              src={`${process.env.PUBLIC_URL}/images/orange-white.svg`}
            />
          </div>
        </BoxLeft>

        <BoxRight>
          <div className="login_form_container">
            <Form
              ref={UcRef}
              onClick={(e) => {
                // e.preventDefault()
                setFocusOn(1)
              }}
              className="pb-30 bb-gray"
              onSubmit={handleSubmitUC}
            >
              <p className="login_form_title">Acesso Fácil</p>
              {/* <p className="login_form_subtitle">
                Para quem quer apenas pagar contas
              </p> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img
                  alt=""
                  src={InfoGrayIcon}
                  style={{ width: 24, height: 24, marginRight: 8 }}
                />
                <div>
                  <p
                    style={{
                      margin: 0,
                      maxWidth: '100%',
                      fontWeight: 500,
                      color: '#6e7a83',
                    }}
                    className="login_form_subtitle"
                  >
                    Serviço temporariamente suspenso.
                  </p>
                  <p
                    style={{ margin: 0, maxWidth: '100%' ,
                    color: '#6e7a83',
                  }}
                    className="login_form_subtitle"
                  >
                    <span>
                      Entre abaixo com CPF/CNPJ e senha e tenha acesso a 2° via
                      de fatura e demais serviços de sua Unidade Consumidora. Se
                      tiver alguma dificuldade ligue para o nosso
                    </span>
                    <span style={{ fontWeight: 500 }}> {process.env.REACT_APP_PAYMENT_ERROR_PHONE}</span>
                  </p>
                </div>
              </div>
              {
                // <p className="login_input_label">UNIDADE CONSUMIDORA</p>
              }
              {/* <div className="login_row">
                <Input
                  className="login_input_container"
                  name="UC"
                  type="tel"
                  placeholder="Insira a UC"
                />

                <FormButton
                  type="submit"
                  onClick={handleSubmitUC}
                  disabled={loadingCaptcha}
                  style={{
                    filter: !loadingCaptcha ? 'grayscale(0)' : 'grayscale(1)',
                  }}
                  isFocused={focusOn === 1}
                  className="login_consult_button ml-10"
                >
                  {loadingUC ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading.svg`}
                      alt="Loading"
                      style={{ height: 40 }}
                    />
                  ) : (
                    'Consultar'
                  )}
                </FormButton>
              </div> */}
            </Form>

            <Form
              onClick={(e) => {
                // e.preventDefault()
                setFocusOn(2)
              }}
              // className="pt-30"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <p className="login_form_title">Sou Cadastrado</p>
              <p className="login_form_subtitle">
                Para quem é titular, quer pagar contas e visualizar o histórico
              </p>

              <p className="login_input_label">CPF OU CNPJ</p>

              <div>
                <Input
                  className="login_input_container"
                  name="document"
                  type="tel"
                  placeholder="Insira o CPF ou CNPJ"
                />
              </div>
              <div className="mt-20">
                <p className="login_input_label">SENHA</p>
              </div>
              <div className="login_row">
                <div className="input_password_container mt-5">
                  <Input
                    className="login_input_password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Insira a senha"
                  />
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      setShowPassword(!showPassword)
                    }}
                    className="input_icon_container"
                  >
                    <img
                      src={showPassword ? VisibilityOn : VisibilityOff}
                      alt={showPassword ? 'on' : 'off'}
                      className="input_password_icon"
                    />
                  </div>
                </div>
                <FormButton
                  disabled={loadingCaptcha}
                  style={{
                    filter: !loadingCaptcha ? 'grayscale(0)' : 'grayscale(1)',
                  }}
                  isFocused={focusOn === 2}
                  type="submit"
                  className="login_consult_button ml-10"
                >
                  {loading ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading.svg`}
                      alt="Loading"
                      style={{ height: 40 }}
                    />
                  ) : (
                    'Entrar'
                  )}
                </FormButton>
              </div>
            </Form>
            {process.env.REACT_APP_AGENCIA && (
              <div className="login_form_bottom_container">
                <p className="login_form_bottom_text mt-30">
                  Não tem uma conta?{' '}
                  <a
                    className="login_form_bottom_text"
                    href={`${process.env.REACT_APP_AGENCIA}/cadastrar-usuario`}
                    target="__blank"
                  >
                    {' '}
                    Cadastre-se{' '}
                  </a>
                </p>

                <a
                  className="login_form_bottom_text mt-10"
                  href={`${process.env.REACT_APP_AGENCIA}/recuperar-senha`}
                  target="__blank"
                >
                  Esqueceu sua senha?
                </a>
              </div>
            )}
          </div>

          <a
            className="login_footer_link"
            href="https://usebill.com.br/"
            target="__blank"
          >
            <div
              style={{
                fontSize: 10,
                marginTop: 20,
                width: 280,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <b
                style={{
                  height: 16,
                  color: '#676767',
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Arrecadação Multicanal
                {'   '}
              </b>

              <img
                style={{ marginLeft: 6, height: 16 }}
                color="#fff"
                src={`${process.env.PUBLIC_URL}/images/logo-bill-gray.svg`}
                alt="Pigz Pay"
              />
            </div>
          </a>

          <div className="box_right_footer_mobile align_self_start">
            <span
              style={{
                height: 16,
                color: '#676767',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Desenvolvido por
            </span>
            <img
              style={{ marginLeft: 6 }}
              alt="orange"
              src={`${process.env.PUBLIC_URL}/images/orange-gray.svg`}
            />
          </div>
        </BoxRight>
      </Box>
    </Container>
  )
}

export default connect((state: any) => ({ modules: state.NOME_CLIENTE }))(Login)
